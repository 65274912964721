<template>
	<v-container fluid style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('policies.title') }}</span>
		</div>

		<v-divider class="my-2" />

		<v-list class="mt-4 mx-auto" max-width="600">
			<v-list-item-group color="primary">
				<v-list-item :to="{ name: 'PoliciesTerms' }">
					<v-list-item-content>
						<v-list-item-title>{{ $t('policies.terms') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item :to="{ name: 'PoliciesPrivacy' }">
					<v-list-item-content>
						<v-list-item-title>{{ $t('policies.privacy') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item :to="{ name: 'PoliciesCookies' }">
					<v-list-item-content>
						<v-list-item-title>{{ $t('policies.cookies') }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-container>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: this.$t('policies.title')
		}
	}
}
</script>
